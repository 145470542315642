




























































import { computed, defineComponent } from "@vue/composition-api";
import { HelpingMessage } from "@/components/atom/helping";
import { accountingPeriodsStore } from "@/store";
import { TaxRegime } from "@edmp/api";

export default defineComponent({
  name: "SubscriptionDeductibility",
  components: {
    HelpingMessage,
  },
  setup() {
    const isIR = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IR_2072
    );
    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );

    return { isIR, isLMNP };
  },
});
