var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form"},[_c('i',[_vm._v("Tous les champs sont obligatoires sauf indication contraire")]),_c('SectionTitle',{attrs:{"icons":['mdi-cog', 'mdi-file-sign'],"title":"Paramètres du contrat de location"}}),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nom du contrat de location","id":"rental-add-name","value":_vm.rentalAgreement.name,"rules":[_vm.rentalAgreementRules.name(_vm.rentalAgreement)],"required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
                value: event,
                path: 'name',
              }); }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","label":"Type du bail","disabled":_vm.isLMNP,"value":_vm.rentalAgreement.type,"items":_vm.typeRentalAgreementItems,"item-text":"text","item-value":"value","rules":[_vm.rentalAgreementRules.type(_vm.rentalAgreement)],"required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', { value: event, path: 'type' }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('DatePicker',{attrs:{"label":"Date de début de contrat","refId":"picker-rental-startAt","required":"","alwaysReopenOnYear":true,"max":_vm.today,"value":_vm.rentalAgreement.startAt,"rules":[_vm.rentalAgreementRules.startAt(_vm.rentalAgreement)]},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
                value: event,
                path: 'startAt',
              }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('DatePicker',{attrs:{"label":"Date de fin de contrat (facultatif)","no-title":"","scrollable":"","alwaysReopenOnYear":true,"min":_vm.rentalAgreement.startAt,"value":_vm.rentalAgreement.endAt,"clearable":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', { value: event, path: 'endAt' }); }}})],1)],1),_vm._l((_vm.tenants),function(tenant,index){return _c('div',{key:index},[_c('SectionTitle',{attrs:{"icons":['mdi-key-variant', 'mdi-account'],"title":'Locataire ' + (index + 1),"deletion":""},on:{"delete":function($event){return _vm.openDeleteTenantModal(tenant.id, index)}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","label":"Forme juridique","value":tenant.type,"items":_vm.tenantType,"rules":[_vm.rentalAgreementRules.tenantType(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', { value: event, path: 'type', index: index }); }}})],1)],1),(tenant.type === _vm.TenantTypeEnum.NATURAL_PERSON)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nom","id":"tenant-lastName","value":tenant.lastName,"rules":[_vm.rentalAgreementRules.tenantlastName(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'lastName',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Prénom","id":"tenant-firstName","value":tenant.firstName,"rules":[_vm.rentalAgreementRules.tenantfirstName(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'firstName',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Email","id":"tenant-email","value":tenant.email,"rules":[_vm.rentalAgreementRules.tenantEmail(tenant)],"required":"","type":"email"},on:{"input":function (event) { return _vm.$emit('updateTenant', { value: event, path: 'email', index: index }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Numéro de téléphone (facultatif)","value":tenant.phone},on:{"input":function (event) { return _vm.$emit('updateTenant', { value: event, path: 'phone', index: index }); }}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Dénomination sociale","value":tenant.denomination,"rules":[_vm.rentalAgreementRules.tenantDenomination(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'denomination',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"SIRET","value":tenant.numSIREN,"rules":[_vm.rentalAgreementRules.tenantNumSIREN(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'numSIREN',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"N° de TVA (facultatif)","value":tenant.numTVA},on:{"input":function (event) { return _vm.$emit('updateTenant', { value: event, path: 'numTVA', index: index }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Email","value":tenant.email,"rules":[_vm.rentalAgreementRules.tenantEmail(tenant)],"required":"","type":"email"},on:{"input":function (event) { return _vm.$emit('updateTenant', { value: event, path: 'email', index: index }); }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"gap mb-5 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"bold"},[_vm._v("Le locataire a t-il un représentant légal ?")]),_c('YesNoSwitch',{attrs:{"value":!!tenant.representative},on:{"click":function (event) { return _vm.$emit('updateRepresentative', { value: event, index: index }); }}})],1)],1),(!!tenant.representative)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Prénom du mandataire","value":tenant.representative.firstName,"rules":[_vm.rentalAgreementRules.representativeFirstName(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'representative.firstName',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nom du mandataire","value":tenant.representative.lastName,"rules":[_vm.rentalAgreementRules.representativeLastName(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'representative.lastName',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Adresse du mandataire","value":tenant.representative.address.street,"rules":[_vm.rentalAgreementRules.representativeStreet(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'representative.address.street',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","label":"Code postal du mandataire","value":tenant.representative.address.zip,"rules":[_vm.rentalAgreementRules.representativeZip(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'representative.address.zip',
                  index: index,
                }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Ville du mandataire","value":tenant.representative.address.city,"rules":[_vm.rentalAgreementRules.representativeCity(tenant)],"required":""},on:{"input":function (event) { return _vm.$emit('updateTenant', {
                  value: event,
                  path: 'representative.address.city',
                  index: index,
                }); }}})],1)],1):_vm._e()],1)}),_c('v-btn',{staticClass:"actions-new-btn",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.addTenant(_vm.tenants)}}},[_c('v-icon',{attrs:{"dense":"","left":"","color":"grey"}},[_vm._v("mdi-plus-circle")]),_vm._v(" Ajouter un locataire ")],1),_c('v-row',[_c('v-col',{staticClass:"submit-btn"},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"primary","type":"button"},on:{"click":_vm.goBack}},[_vm._v(" Retour ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","id":"rental-flow-tenant-validate","type":"button"},on:{"click":_vm.goNext}},[_vm._v(" Suivant ")])],1)],1)],2),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"60%","scrollable":""},model:{value:(_vm.displayDeleteTenantModal),callback:function ($$v) {_vm.displayDeleteTenantModal=$$v},expression:"displayDeleteTenantModal"}},[_c('v-card',{staticClass:"mt-2 py-6"},[_c('p',{staticClass:"align-center"},[_vm._v(" La suppression d'un locataire est irréversible. Êtes-vous sûr de vouloir le supprimer ? ")]),_c('div',{staticClass:"btns-center"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""},on:{"click":function($event){_vm.displayDeleteTenantModal = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.deleteTenant()}}},[_vm._v(" Supprimer ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }