

























































import { defineComponent, computed, onMounted } from "@vue/composition-api";
import GenericTable, {
  TableHeaderItem,
} from "@/components/atom/table/GenericTable.vue";
import { accountingPeriodsStore, regularizationsStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";
import PageHeader from "@/components/atom/PageHeader.vue";

export default defineComponent({
  name: "RegularizationHistory",
  components: { PageHeader, GenericTable },
  setup(props, context) {
    const regularizations = computed(
      () => regularizationsStore.regularizations
    );
    const historyTableHeaders: TableHeaderItem[] = [
      { text: "Date", value: "date" },
      { text: "Provisions pour charges", value: "provisions" },
      { text: "Charges réelles", value: "charges" },
      { text: "Résultat", value: "result" },
    ];

    const deleteRegularization = (id) =>
      regularizationsStore.removeRegularization(id);

    onMounted(() =>
      regularizationsStore.fetchRegularizations(
        context.root.$route.params.rentalAgreementId
      )
    );

    return {
      historyTableHeaders,
      regularizations,
      deleteRegularization,
      ROUTE_NAMES,
      accountingPeriodsStore,
    };
  },
});
