

































































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import PageHeader from "@/components/atom/PageHeader.vue";
import { ROUTE_NAMES } from "@/router/routes";
import IndexationResult from "@/components/core/rentalAgreements/IndexationResult.vue";
import { rentalsService } from "@/services";
import { RentalAgreementsService } from "@edmp/api";
import RentIndexationOut = RentalAgreementsService.RentIndexationOut;
import { accountingPeriodsStore } from "@/store";
import IndexationHistory from "@/components/core/rentalAgreements/IndexationHistory.vue";

export default defineComponent({
  name: "Indexation",
  computed: {
    ROUTE_NAMES() {
      return ROUTE_NAMES;
    },
  },
  components: { IndexationHistory, IndexationResult, PageHeader },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String,
      required: true,
    },
    rentalAgreementId: {
      type: String,
      required: true,
    },
    tab: { type: String, required: false },
  },
  setup(props) {
    const isLoading = ref(false);
    const tabItemIndex = ref(props.tab ? parseInt(props.tab) : 0);
    const rentIndexationOut = ref<RentIndexationOut>();

    const getAgreement = () => {
      isLoading.value = true;
      rentalsService.agreements
        .rentIndexation({ id: props.rentalAgreementId })
        .then((response) => {
          isLoading.value = false;
          rentIndexationOut.value = response;
        });
    };

    onMounted(() => {
      if (props.rentalAgreementId) {
        getAgreement();
      }
    });

    return {
      tabItemIndex,
      isLoading,
      rentIndexationOut,
      getAgreement,
      accountingPeriodsStore,
    };
  },
});
