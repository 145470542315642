import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
// import { accountingPeriodsStore } from "@/store";
import {
  Ability,
  AbilityBuilder,
  defineAbility,
  ExtractSubjectType,
  MongoQuery,
  Subject,
  SubjectRawRule,
} from "@casl/ability";
import {
  AccessModel,
  SubscriptionsModel,
  User,
  UsersLib,
  ProductsModel,
  TaxRegime,
} from "@edmp/api";
// import { computed } from "@vue/composition-api";

// const isLMNP = computed(() => accountingPeriodsStore.isLMNP);

/* Just a message for the user. */
const msgPremiumOrOptimum =
  "Votre abonnement ne vous permet pas d'accéder à cette fonctionnalité. Si vous souhaitez y accéder, passez à l'abonnement PREMIUM ou OPTIMUM";
const msgFree =
  "Cette fonctionnalité n'est pas disponible en période d'essai. Si vous souhaitez y accéder, pensez à vous abonner";
const msgPlan = "Ce service nécessite un abonnement supérieur.";
const msgPlanIR =
  "Ce service est réservé aux Abonnés Basic ou Premium. Il n'est pas accessible pendant l'essai gratuit.";
const msgPlanIS =
  "Ce service est réservé aux Abonnés Optimum. Il n'est pas accessible pendant l'essai gratuit.";
const msgPlanLmnp =
  "Ce service est réservé aux Abonnés LMNP. Il n'est pas accessible pendant l'essai gratuit.";

const msgUpgradePlan = (subscriptionId?: string) => {
  const message = "Ce service nécessite un abonnement supérieur.";
  if (subscriptionId) {
    const resolved = router.resolve({
      name: ROUTE_NAMES.SubscriptionDetails,
      params: { subscriptionId },
    });
    return message + ` <a href="${resolved.href}">Voir mon abonnement</a>`;
  }
  return message;
};
// For good redirection subscriptions
const msgUpgradePlanForFree = (messageTaxRegime?: string) => {
  let message;
  if (messageTaxRegime) {
    message = "Ce service est réservé aux Abonnés " + messageTaxRegime + ".";
  } else {
    message = "Ce service nécessite un abonnement supérieur.";
  }

  const resolved = router.resolve({
    name: ROUTE_NAMES.Subscriptions,
  });
  return message + ` <a href="${resolved.href}">Voir mon abonnement</a>`;
};
const msgNoRight =
  "Vous n'avez pas les droits nécessaires pour accéder à ce service";

// ALL abilities
// User rules and Subscription rules are concatenated in ability
export const ability = defineAbility((can) => {
  // DO NOT USE ME
  can("do", "Noting"); // Will be erase with update , it is just for init
}, {});

let userRules: SubjectRawRule<
  string,
  ExtractSubjectType<Subject>,
  MongoQuery
>[] = [];
let productRules: SubjectRawRule<
  string,
  ExtractSubjectType<Subject>,
  MongoQuery
>[] = [];

/**
 * This class allow to update permissions from User or Subscription events
 **/
class PermissionsService {
  removeAbility() {
    ability.update([]);
  }

  // Update abilities who need to know data about user
  updateUserAbility(user: User) {
    const ownedProducts = UsersLib.getProductIdsByMyProductAccessType(
      user,
      AccessModel.AccessTypes.OWNED
    );

    const { can, rules } = new AbilityBuilder(Ability);

    can("test", "user");

    can("portal", "Subscription", {
      productId: { $in: ownedProducts },
    });
    can("add", "Subscription", {
      productId: { $in: ownedProducts },
    });

    can("addForOwner", "BankAccountOwner", {
      productId: { $in: ownedProducts },
    });

    can("add", "RentalBuilding", {
      productId: { $in: ownedProducts },
    }).because(msgNoRight);
    can("update", "RentalBuilding", {
      productId: { $in: ownedProducts },
    }).because(msgNoRight);

    // Save user rules
    userRules = rules;

    /// Return all rules
    ability.update(rules.concat(productRules));
  }

  updateProductAbility(
    subscription: SubscriptionsModel.Subscription | undefined,
    product?: ProductsModel.Product
  ) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);
    const type = subscription?.plan?.type ?? "Unknown";
    const productType = product?.id ?? "Unknown";
    can("plan", type); // Just for trace
    can("visit", "BankAccount");
    can("visit", "Product");
    can("visit", "Subscription");
    can("visit", "User");
    can("add", "BankAccount");
    can("categorize", "Transaction");
    can("invite", "Product");
    can("addTenant", "RentalManagement");
    can("rentReceipt", "RentalManagement");
    can("addRealEstateAsset", "RentalManagement");
    can("import", "RentalManagement");
    can("visit", "ModelsLibrary");
    cannot("manageTenantServices", "RentalManagement").because(
      msgUpgradePlan(subscription?.id)
    );

    if (subscription?.status === SubscriptionsModel.SubscriptionStatus.end) {
      // Can't navigate.
      // Can only access to subscription
      cannot("add", "BankAccount").because(msgPremiumOrOptimum);
      cannot("add", "Product").because(
        product?.lastTaxRegime === TaxRegime.IR_2072
          ? msgPlanIR
          : product?.lastTaxRegime === TaxRegime.IS_2065
          ? msgPlanIS
          : product?.lastTaxRegime === TaxRegime.LMNP_2031
          ? msgPlanLmnp
          : msgUpgradePlan(subscription.id)
      );
    } else if (
      subscription?.status === SubscriptionsModel.SubscriptionStatus.suspend
    ) {
      // Can't navigate.
      // Can only access to subscription
      cannot("add", "BankAccount").because(msgPremiumOrOptimum);
      cannot("add", "Product").because(
        product?.lastTaxRegime === TaxRegime.IR_2072
          ? msgPlanIR
          : product?.lastTaxRegime === TaxRegime.IS_2065
          ? msgPlanIS
          : product?.lastTaxRegime === TaxRegime.LMNP_2031
          ? msgPlanLmnp
          : msgPlan
      );
      console.log("suspend");
    } else if (
      subscription?.status === SubscriptionsModel.SubscriptionStatus.pending
    ) {
      can("add", "Product");
    } else {
      // Can navigate all
      can("visit", "all");

      // Default permissions
      can("add", "Product");
      can("suggest", "Transaction");
      can("import", "Transaction");

      // Specific permission for a Plan
      // - If you add a permission, you SHOULD add it in all plan
      // - Only cannot rule (Inverted rule) allow to use because() in order to retrieve message in Exception
      if (SubscriptionsModel.PlanType.Basic === type) {
        cannot("add", "BankAccount", { size: { $gte: 1 } }).because(
          msgPremiumOrOptimum
        );
        cannot("suggest", "Transaction").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("visit", "ModelsLibrary").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("manage", "Logo").because(msgUpgradePlan(subscription?.id));
      } else if (SubscriptionsModel.PlanType.Premium === type) {
        can("manage", "all");
        can("manageTenantServices", "RentalManagement");
      } else if (SubscriptionsModel.PlanType.Optimum === type) {
        can("manage", "all");
        can("manageTenantServices", "RentalManagement");
      } else if (SubscriptionsModel.PlanType.Solo === type) {
        can("add", "RealEstateLoan");
        cannot("add", "BankAccount").because(msgUpgradePlan(subscription?.id));
        cannot("suggest", "Transaction").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("import", "Transaction").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("rentReceipt", "RentalManagement").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("addTenant", "RentalManagement").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("addRealEstateAsset", "RentalManagement", {
          size: { $gte: 1 },
        }).because(msgUpgradePlan(subscription?.id));
        cannot("invite", "Product").because(msgUpgradePlan(subscription?.id));
        cannot("categorize", "Transaction", {
          categoryNumber: {
            $in: [
              "706000", // Rent collected
              "445660", // VAT Deductible
              "445720", // VAT collected
              "445800", // VAT State Payment
            ],
          },
        }).because(msgUpgradePlan(subscription?.id));

        cannot("visit", "ModelsLibrary").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("manage", "Logo").because(msgUpgradePlan(subscription?.id));
      } else if (SubscriptionsModel.PlanType.LMNPEssential === type) {
        can("manage", "all");
        cannot("manageTenantServices", "RentalManagement").because(
          msgUpgradePlan(subscription?.id)
        );
        cannot("visit", "ModelsLibrary").because(
          msgUpgradePlan(subscription?.id)
        );
      } else if (SubscriptionsModel.PlanType.LMNPOptimum === type) {
        can("manage", "all");
        can("manageTenantServices", "RentalManagement");
      } else if (ProductsModel.ProductTypes.LMNP === productType) {
        cannot("import", "Transaction").because(msgPlanLmnp);
      } else {
        // Free or onboarding
        cannot("add", "Product").because(
          product?.lastTaxRegime === TaxRegime.IR_2072
            ? msgPlanIR
            : product?.lastTaxRegime === TaxRegime.IS_2065
            ? msgPlanIS
            : product?.lastTaxRegime === TaxRegime.LMNP_2031
            ? msgPlanLmnp
            : msgPlan
        );
        cannot("add", "BankAccount", { size: { $gte: 1 } }).because(msgFree);
        cannot("import", "Transaction").because(
          product?.lastTaxRegime === TaxRegime.IR_2072
            ? msgPlanIR
            : product?.lastTaxRegime === TaxRegime.IS_2065
            ? msgPlanIS
            : product?.lastTaxRegime === TaxRegime.LMNP_2031
            ? msgPlanLmnp
            : msgPlan
        );
        cannot("visit", "ModelsLibrary").because(
          product?.lastTaxRegime === TaxRegime.IR_2072
            ? msgUpgradePlanForFree("Premium")
            : product?.lastTaxRegime === TaxRegime.IS_2065
            ? msgUpgradePlanForFree("Optimum")
            : ""
        );
        cannot("manage", "Logo").because(msgUpgradePlanForFree());
        can("manageTenantServices", "RentalManagement");
      }
    }

    // Save product rules
    productRules = rules;
    // Return all rules
    ability.update(userRules.concat(productRules));
  }
}

// Export a singleton instance in the global namespace
export const permissionsService = new PermissionsService();

// Load default rules if product not created yet
permissionsService.updateProductAbility(undefined);
