var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('PageHeader',[_vm._v("Historique des régularisations")]),_c('div',{staticClass:"realEstateAsset-header"},[_c('router-link',{staticClass:"products-back d-flex align-center",attrs:{"to":_vm.$route.name === _vm.ROUTE_NAMES.RegularizationHistory
            ? {
                name: _vm.ROUTE_NAMES.RealEstate,
                query: { tab: _vm.accountingPeriodsStore.isIR ? '1' : '2' },
              }
            : _vm.$route.name === _vm.ROUTE_NAMES.RegularizationHistoryLMNP
            ? {
                name: _vm.ROUTE_NAMES.RealEstateLMNP,
                query: { tabIndex: '4' },
              }
            : { name: _vm.ROUTE_NAMES.RentalAgreements }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Mes locations ")],1)],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_vm._l((_vm.historyTableHeaders),function(head){return _c('th',{key:head.value,attrs:{"id":head.value}},[_vm._v(" "+_vm._s(head.text)+" ")])}),_c('th',[_vm._v("Action")])],2)]),_c('tbody',_vm._l((_vm.regularizations),function(item){return _c('tr',{key:item.id},[_vm._l((_vm.historyTableHeaders),function(head){return _c('td',{key:head.value},[_vm._v(" "+_vm._s(item[head.value])+" "+_vm._s(head.value !== "date" ? "€" : null)+" ")])}),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteRegularization(item.id)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1)],2)}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }