



































































import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
  PropType,
} from "@vue/composition-api";
import PageHeader from "@/components/atom/PageHeader.vue";
import { ROUTE_NAMES } from "@/router/routes";
import RentalAgreementFinancial from "@/components/core/rentalAgreements/rentalAgreementFlow/RentalAgreementFinancial.vue";
import RentalAgreementTenants from "@/components/core/rentalAgreements/rentalAgreementFlow/RentalAgreementTenants.vue";
import RentalAgreementTypeView from "@/components/core/rentalAgreements/rentalAgreementFlow/RentalAgreementType.vue";
import { useRents } from "@/components/core/rentalAgreements/rentalAgreementFlow/rents.usable";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  tenantsStore,
} from "@/store";
import {
  InseeData,
  NewTenant,
  Tenant,
  RentalAgreement,
  RentIndexationTypeParamEnum,
  TenantUpdate,
  TenantTypeEnum,
  RentalAgreementType,
} from "@edmp/api";
import Vue from "vue";
import { rentalsService } from "@/services";

export default defineComponent({
  name: "RentalAgreementFlow",
  components: {
    RentalAgreementTypeView,
    RentalAgreementTenants,
    RentalAgreementFinancial,
    PageHeader,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    rentalId: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    smallDisplay: {
      type: Boolean,
      default: false,
    },
    displayCancel: {
      type: Boolean,
      default: false,
    },
    realEstateAssetId: { type: String, required: true },
  },
  setup(props, context) {
    const { defaultTenant, defaultRentalAgreement, defaultRepresentative } =
      useRents();

    const isNew = computed(() => {
      return (
        rentalAgreementId.value === null || rentalAgreementId.value === "new"
      );
    });

    const rentalAgreementId = computed(() => {
      return context.root.$route.params.rentalAgreementId || props.rentalId;
    });

    const rentalAgreement = ref({
      ...defaultRentalAgreement,
      type: RentalAgreementType.FURNISHED,
    });
    const tenants: Ref<Tenant[] | NewTenant[]> = ref([{ ...defaultTenant }]);

    const step = ref(1);

    const fetchingInseeData = ref(true);
    const inseeDataLists = ref<InseeData[]>([]);

    const deleteTenant = (index) => {
      tenants.value.splice(index, 1);
    };

    const redirectToPreviousPage = () => {
      if (context.root.$route.name === ROUTE_NAMES.RealEstateLMNP) {
        context.emit("cancelEdition");
      } else {
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          query: { tab: accountingPeriodsStore.isIR ? "1" : "2" },
        });
      }
    };

    const goNext = () => {
      step.value = isNew.value ? 3 : 2;
    };

    const validate = async () => {
      if (isNew.value) {
        const createdRentalAgreement =
          await rentalAgreementsStore.createRentalAgreement({
            ...rentalAgreement.value,
            product: {
              id: productsStore.currentId,
              realEstateAsset: {
                id:
                  props.realEstateAssetId ||
                  context.root.$route.params.realEstateAssetId
                    ? props.realEstateAssetId ||
                      context.root.$route.params.realEstateAssetId
                    : realEstateAssetsStore.selectedRealEstateAssetId,
                rentalUnit: {
                  id: realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
                    props.realEstateAssetId ||
                      context.root.$route.params.realEstateAssetId
                      ? props.realEstateAssetId ||
                          context.root.$route.params.realEstateAssetId
                      : realEstateAssetsStore.selectedRealEstateAssetId
                  ).id,
                },
              },
            },
          });

        for (const tenant of tenants.value) {
          if (tenant.type === TenantTypeEnum.NATURAL_PERSON) {
            tenant.denomination = "";
            tenant.numTVA = "";
            tenant.numSIREN = "";
          } else {
            tenant.firstName = "";
            tenant.lastName = "";
            tenant.phone = "";
          }
          await tenantsStore.createTenant({
            ...tenant,
            productId: productsStore.currentId,
            realEstateAssetId:
              props.realEstateAssetId ||
              context.root.$route.params.realEstateAssetId
                ? props.realEstateAssetId ||
                  context.root.$route.params.realEstateAssetId
                : realEstateAssetsStore.selectedRealEstateAssetId,
            rentalAgreementId: createdRentalAgreement.id,
          });
        }

        coreStore.displayFeedback({
          message: "Votre location a bien été créée.",
        });
        context.emit("validate", createdRentalAgreement.id);
      } else if (rentalAgreementId.value !== null) {
        await rentalAgreementsStore.updateRentalAgreement({
          ...rentalAgreement.value,
          id: rentalAgreementId.value,
        });

        for (const tenant of tenants.value) {
          if (tenant.type === TenantTypeEnum.NATURAL_PERSON) {
            tenant.denomination = "";
            tenant.numTVA = "";
            tenant.numSIREN = "";
          } else {
            tenant.firstName = "";
            tenant.lastName = "";
            tenant.phone = "";
          }
          if ((tenant as Tenant).id) {
            await tenantsStore.updateTenant(tenant as TenantUpdate);
          } else if (rentalAgreement.value.product.realEstateAsset) {
            await tenantsStore.createTenant({
              ...tenant,
              productId: productsStore.currentId,
              realEstateAssetId:
                rentalAgreement.value.product.realEstateAsset.id,
              rentalAgreementId: context.root.$route.params.rentalAgreementId,
            });
          }
        }

        coreStore.displayFeedback({
          message: "Votre location a bien été mise à jour.",
        });
        context.emit("validate", rentalAgreementId.value);
      }
      realEstateAssetsStore.selectRealEstateAssetId("");
      redirectToPreviousPage();
    };

    watch(
      rentalAgreementId,
      async () => {
        if (!isNew.value && rentalAgreementId.value !== null) {
          rentalAgreement.value = JSON.parse(
            JSON.stringify(
              rentalAgreementsStore.getRentalAgreement(
                rentalAgreementId.value
              ) as RentalAgreement
            )
          );

          await tenantsStore.fetchTenants({
            productId: productsStore.currentId,
          });
          tenants.value = JSON.parse(
            JSON.stringify(
              tenantsStore.getTenantsByRentalAgreementId(
                rentalAgreementId.value
              )
            )
          );
        }
      },
      { immediate: true }
    );

    const updateRentalAgreementField = (value: string, path: string) => {
      const pList = path.split(".");
      const key = pList.pop();
      const pointer = pList.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue] === undefined)
          Vue.set(accumulator, currentValue, {});
        return accumulator[currentValue];
      }, rentalAgreement.value);
      Vue.set(pointer, key as string, value);
    };

    const updateTenantField = (value: string, path: string, index: number) => {
      const pList = path.split(".");
      const key = pList.pop();
      if (pList[0] !== "representative") {
        const pointer = pList.reduce((accumulator, currentValue) => {
          if (accumulator[currentValue] === undefined) {
            Vue.set(accumulator, currentValue, {});
          }
          return accumulator[currentValue];
        }, tenants.value[index]);
        Vue.set(pointer, key as string, value);
      } else {
        tenants.value.splice(index, 1, {
          ...tenants.value[index],
          representative: Object.assign(
            {},
            tenants.value[index].representative,
            pList[1] !== "address"
              ? { [key as string]: value }
              : {
                  ...tenants.value[index].representative,
                  address: {
                    ...tenants.value[index].representative?.address,
                    [key as string]: value,
                  },
                }
          ),
        });
      }
    };

    function updateRepresentative(value: boolean, index: number) {
      if (value === true) {
        Vue.set(tenants.value[index], "representative", defaultRepresentative);
      } else Vue.set(tenants.value[index], "representative", undefined);
    }

    onMounted(() => {
      rentalsService.agreements
        .getListIndexations(
          rentalAgreement.value.financialConditions.indexation?.type ??
            RentIndexationTypeParamEnum.IRL_metro
        )
        .then((response) => {
          fetchingInseeData.value = false;
          inseeDataLists.value = response;
        });
    });

    return {
      isNew,
      goNext,
      step,
      validate,
      rentalAgreement,
      tenants,
      ROUTE_NAMES,
      updateRentalAgreementField,
      updateTenantField,
      updateRepresentative,
      fetchingInseeData,
      redirectToPreviousPage,
      deleteTenant,
      accountingPeriodsStore,
    };
  },
});
