












































































































































































































import { SubscriptionsModel, TaxRegime } from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
} from "@vue/composition-api";
import moment from "moment";
import { useSubscription } from "../subscription.usable";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import SubscriptionCancel from "../cancel/SubscriptionCancel.vue";
import { accountingPeriodsStore, coreStore, subscriptionsStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

export default defineComponent({
  name: "SubscriptionInfos",
  components: { SubscriptionCancel },
  props: {
    subscription: {
      type: Object as PropType<SubscriptionsModel.Subscription>,
      required: true,
    },
    subscriptionPaymentRetryIsLoading: { type: Boolean, default: false },
  },
  setup(props) {
    const { getRedirectUri, goStripePortal } = useSubscription(
      props.subscription.id
    );

    const isIR = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IR_2072
    );

    const subscriptionCompute = computed(() =>
      Object.assign(props.subscription, {
        startAtText: moment(props.subscription.startAt).format("DD/MM/YYYY"),
        endAtText: moment(props.subscription.endAt).format("DD/MM/YYYY"),
        cancelAtText: moment(props.subscription.cancelAt).format("DD/MM/YYYY"),
        canceledATextt: moment(props.subscription.canceledAt).format(
          "DD/MM/YYYY"
        ),
        payment: Object.assign(props.subscription.payment, {
          paymentMethodText:
            props.subscription.payment.paymentMethod ==
            SubscriptionsModel.PaymentMethodType.sepaDebit
              ? "Virement SEPA"
              : props.subscription.payment.paymentMethod ==
                SubscriptionsModel.PaymentMethodType.card
              ? "Carte bancaire"
              : props.subscription.payment.paymentMethod ==
                SubscriptionsModel.PaymentMethodType.unknown
              ? "Aucun"
              : "Inconnue",
        }),
      })
    );
    const subscriptionCancelIsOpen: Ref<boolean> = ref(false);
    const subscriptionReactivateIsLoading: Ref<boolean> = ref(false);

    function testSubscription() {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "add",
          subject("Subscription", {
            productId: subscriptionCompute.value?.productId,
          })
        );
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Vous n'avez pas les droits nécessaires pour accéder à ce service",
          });
        }
        return false;
      }
      return true;
    }
    const cancelSubscription = async () => {
      if (testSubscription()) {
        subscriptionCancelIsOpen.value = true;
      }
    };

    // Reactivate subscription
    const reactivateSubscription = async () => {
      if (testSubscription()) {
        subscriptionReactivateIsLoading.value = true;
        await subscriptionsStore.cancelSubscription({
          id: props.subscription.id,
          cancelAtPeriodEnd: false,
        });
        coreStore.displayFeedback({
          message: "Votre demande est bien prise en compte.",
        });
        subscriptionReactivateIsLoading.value = false;
      }
    };

    return {
      isIR,
      subscriptionCompute,
      SubscriptionStatus: SubscriptionsModel.SubscriptionStatus,
      PaymentStatus: SubscriptionsModel.PaymentStatus,
      PlanType: SubscriptionsModel.PlanType,
      getRedirectUri,
      subscriptionCancelIsOpen,
      subscriptionReactivateIsLoading,
      cancelSubscription,
      goStripePortal,
      reactivateSubscription,
    };
  },
});
