var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstateAssetView"},[_c('div',[_c('PageHeader',[_vm._v("Régularisation des charges")]),_c('div',{staticClass:"realEstateAsset-header"},[_c('router-link',{staticClass:"products-back d-flex align-center",attrs:{"to":_vm.$route.name === _vm.ROUTE_NAMES.Regularization
            ? {
                name: _vm.ROUTE_NAMES.RealEstate,
                query: { tab: _vm.accountingPeriodsStore.isIR ? '1' : '2' },
              }
            : _vm.$route.name === _vm.ROUTE_NAMES.RegularizationLMNP
            ? {
                name: _vm.ROUTE_NAMES.RealEstateLMNP,
                query: { tabIndex: '4' },
              }
            : { name: _vm.ROUTE_NAMES.RentalAgreements }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Mes locations ")],1)],1)],1),_c('v-card',[_c('v-card-text',[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',{staticClass:"pa-6"},[_c('v-stepper-step',{attrs:{"editable":_vm.step > 1,"step":"1"},on:{"click":function($event){return _vm.resetRegularization()}}},[_vm._v(" Initialisation ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"editable":_vm.step > 2,"step":"2"}},[_vm._v(" Apurement des charges ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"editable":_vm.step > 3,"step":"3"}},[_vm._v(" Résultats ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('RegularizationInitial',{staticClass:"px-2",attrs:{"regularization":_vm.regularization},on:{"goBack":function($event){return _vm.redirectToPreviousPage()},"goNext":function($event){_vm.step = 2},"updateRegularization":function (event) { return _vm.updateRegularizationField(event.value, event.path); }}})],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('RegularizationChargeClearance',{staticClass:"px-2",attrs:{"regularization":_vm.regularization,"isFullYear":_vm.isFullYear},on:{"goNext":function($event){_vm.step = 3},"goBack":function($event){_vm.step = 1},"updateRegularization":function (event) { return _vm.updateRegularizationField(event.value, event.path); }}})],1),_c('v-stepper-content',{attrs:{"step":3}},[_c('RegularizationResult',{staticClass:"px-2",attrs:{"regularization":_vm.regularization},on:{"goBack":function($event){_vm.step = 2},"validate":_vm.validate,"updateRegularization":function (event) { return _vm.updateRegularizationField(event.value, event.path); }}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }