


































































































import { RentalAgreement, RealEstateAsset } from "@edmp/api";
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  Ref,
} from "@vue/composition-api";

import { rentalAgreementsStore } from "@/store";
import RentalAgreementFlowCard from "@/components/core/rentalAgreements/rentalAgreementFlow/RentalAgreementFlowCard.vue";
import RentalAgreementCard from "@/components/core/rentalAgreements/RentalAgreementCard.vue";

export default defineComponent({
  name: "RealEstateRentalLMNP",
  components: {
    RentalAgreementFlowCard,
    RentalAgreementCard,
  },
  props: {
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    isFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const rentalList = computed(() => {
      return rentalAgreementsStore.getRentalAgreementsByRealEstateAssetId(
        props.realEstateAsset.id
      );
    });

    // Function
    const scrollToElement = (elementId) =>
      nextTick(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });

    // Fixed Asset
    const openRentalForm: Ref<boolean> = ref(
      props.isFlow || rentalList.value.length === 0
    );
    const selectedRental: Ref<Partial<RentalAgreement> | undefined> =
      ref(undefined);
    const selectRental = (rental) => {
      selectedRental.value = rental;
      openRentalForm.value = true;
      isEditableRental.value = true;
      scrollToElement("rentalForm-component");
    };
    const closeRentalForm = () => {
      openRentalForm.value = false;
      selectedRental.value = undefined;
      context.emit("openRental", false);
    };
    const createAnotherRental = () => {
      selectedRental.value = undefined;
      openRentalForm.value = true;
      isEditableRental.value = true;
      scrollToElement("rentalForm-component");
    };

    const validateRental = (rentalId: string) => {
      selectedRental.value = rentalList.value.find(
        (rental) => rental.id === rentalId
      );
      setEditingRental(false);
      scrollToElement("rentalAmortisationTable-component");
    };
    const isEditableRental: Ref<boolean> = ref(
      props.isFlow || rentalList.value.length === 0
    );
    const setEditingRental = (val: boolean) => {
      isEditableRental.value = val;
    };

    return {
      rentalList,
      openRentalForm,
      selectedRental,
      isEditableRental,
      selectRental,
      validateRental,
      closeRentalForm,
      createAnotherRental,
    };
  },
});
